import $ from "jquery"

main();

function main() {
    $(() => {
        setNavPosition(calcNavOffset());
        setNavEvents();
    })
}

function setNavEvents() {
    $(".btn-open").click(function() {
        showNav();
    });

    $(".btn-close").click(function() {
        closeNav();
    });

    $("body").click(function(e) {
        const isClickOnNav = $(".nav").has(e.target).length || $(e.target).hasClass("nav");

        if ($(".nav").css("right") === "0px" && !isClickOnNav) {
            $(".nav").animate({
                right: calcNavOffset(),
            }, "slow");
            $(".btn-open").fadeIn();
            $("html").css({ 'overflow-y': 'initial' });
        }
    });

    $(window).resize(function() {
        if($(".nav").css("right") != "0px")
            setNavPosition(calcNavOffset());
    });
}

function showNav() {
    $(".nav").animate({
        right: '0',
    }, 500);
    $(".btn-open").fadeOut();
    if (screen.height > 770) {
        $("html").css({ 'overflow-y': 'hidden' });
    }
}

function closeNav() {
    const right = "-" + $(".nav").outerWidth(true) + "px";
    $(".nav").animate({
        right: right,
    }, "slow");
    $(".btn-open").fadeIn();
    $("html").css({ 'overflow-y': 'initial' });
}

function setNavPosition(offset) {
    $(".nav").css("right", offset);
}

function calcNavOffset() {
    return "-" + $(".nav").outerWidth(true) + "px";
}